import { template as template_55d0ce8b04444231b6e5d447a30d921c } from "@ember/template-compiler";
const FKControlMenuContainer = template_55d0ce8b04444231b6e5d447a30d921c(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
