import { template as template_3c3808543b5042abb8f5a41a01d5f91f } from "@ember/template-compiler";
const FKLabel = template_3c3808543b5042abb8f5a41a01d5f91f(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
